
import { defineComponent, ref } from 'vue';
import { required, validEmail, validCpf, validPhone, isSame, validDate } from '@/utils/validationFunctions';

import LoginInput from '@/components/LoginInput.vue';
import Form from "@/components/Form.vue";
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';
import Loading from '@/components/Loading.vue';

import UsuarioService from '@/services/UsuarioService';
import { useRouter } from 'vue-router';
import vm from '@/viewModels/RegisterViewModel';
import { useMediaPermissions } from '@/plugins/mediaPermission';

const RegisterPage = defineComponent({
    components: { LoginInput, Form, ConfigureMediaModal, Loading },
    setup() {
        // Hooks
        const { push } = useRouter();
        const checkPermission = useMediaPermissions();
        const confirmarSenha = ref("");
        const mediaModal = ref<typeof ConfigureMediaModal | null>(null);
        const loading = ref(false);


        const validateUserCpf = (cpf: string) => {
            return new Promise((resolve) => {
                validCpf(cpf)
                    .then(resp => {
                        if(resp.length > 0) {
                            resolve(resp);
                        }
                        else {
                            const [request] = UsuarioService.GetUsers({ 
                                cpf: cpf.replace(new RegExp("[.-]", "gi"), '')
                            });
                            request
                                .then(user => {
                                    resolve(user.length > 0 ? "Ja existe um usuário cadastrado com esse CPF" : "");
                                })
                                .catch(() => {
                                    resolve("Não foi possível validar o CPF, por favor, tente novamente");
                                });
                        }
                    });
               
            });
        };

        const validateUserEmail = (email: string) => {
            return new Promise((resolve) => {
                validEmail(email)
                    .then(resp => {
                        if(resp.length > 0) {
                            resolve(resp);
                        }
                        else {
                            const [request] = UsuarioService.GetUsers({ email });
                            request
                                .then(user => {
                                    resolve(user.length > 0 ? "Ja existe um usuário cadastrado com esse e-mail" : "");
                                })
                                .catch(() => {
                                    resolve("Não foi possível validar o CPF, por favor, tente novamente");
                                });
                        }
                    });
               
            });
        };

        const submit = async (data: { isValid: boolean; erros: string[]}) => {
            
            if(data.isValid) {
                loading.value = true;
                const granted = await checkPermission();
                if(granted) {
                    await mediaModal.value!.configure();
                    push({ name: 'picture' });
                }
                loading.value = false;
            }
        };

        return { vm, required, submit, validPhone, isSame, confirmarSenha, validDate, validateUserCpf, validateUserEmail, mediaModal, loading };
    }
});

export default RegisterPage;
