<template>
    <div class="min-h-screen bg-white flex items-center justify-end relative">

        <div class="w-5/12 px-16 bg-primary fixed left-0 h-screen flex items-center top-0">
            <div class="w-full max-w-sm" style="min-height: 320px">
                <h1 class="text-3xl font-bold text-white mb-6">Uma forma segura para realizar o seu exame</h1>
                <p class="text-white">Sua prova em formato on-line. Faça o seu exame de forma segura e sem sair de casa.</p>
            </div>
        </div>

        <!-- Wrapper -->
        <div class="w-7/12 py-12" style="min-height: 320px">
            <div class="w-full max-w-sm 2xl:max-w-lg mx-auto">
                <h1 class="text-3xl font-bold mb-6">Bem-vindo</h1>
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue';

const LoginMainView = defineComponent({
});

export default LoginMainView;
</script>

<style>

</style>