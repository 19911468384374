import UserPostDTO from "@/DTOs/UserPostDTO";
import UsuarioService from "@/services/UsuarioService";
import { reactive } from "vue";

class RegisterViewModel {
    public form = reactive<UserPostDTO>({
        nome: "",
        cpf: "",
        dataNascimento: "01/01/1900",
        codUsuario: "",
        email: "",
        senha: "",
        telefones: [
            {
                numero: "",
                tipo: "SECUNDARIO"
            }
        ],
        roleId: 1,
        clientId: 2,
        imagem: ""
    });

    public submit(picture: string) {
        const user = { ...this.form };
        user.imagem = picture;
        user.cpf = user.cpf.replace(new RegExp("[.-]", "gi"), '');
        user.dataNascimento = user.dataNascimento + " 00:00:00";
    
        const [request] = UsuarioService.PostUser(user);

        return request;
    }
    
}

export default new RegisterViewModel();