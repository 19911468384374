<template>
    <form @submit.prevent="submit" ref="form">
        <slot/>
    </form>
</template>

<script lang='ts'>
import { defineComponent, provide, ref } from 'vue';

export interface FormField {
    name: string;
    error: string;
    validation: ((value: string) => Promise<string>)[];
}

const Form = defineComponent({
    props: {
        validateOnBlur: {
            type: Boolean,
            required: true
        }
    },
    setup(props, context) {
        const form = ref<HTMLFormElement | null>(null);
        const fields = ref<FormField[]>([]);
        let validateOnInput = false;

        const setField = (data: FormField) => {
            fields.value.push(data);
        };

        const validateField = async (fieldName: string, value: string) => {
            const field = fields.value.find(el => el.name == fieldName);
            if(field) {

                for (const rule of field.validation) {
                    const response = await rule(value.toString());
                    field.error = response.replace("$NAME", fieldName);
                }
            }
        };

        const validate = async () => {
            const formData = new FormData(form.value!);

            for(const [key, value] of formData.entries()) {
                await validateField(key, value.toString());
            }

            return fields.value.find(el => el.error.length > 0) == null;
        };

        const onInput = async (fieldName: string, value: string) => {
            if(validateOnInput) {
                await validateField(fieldName, value);
            }
        };

        const submit = () => {
            validateOnInput = true;
            validate()
                .then(isValid => {

                    context.emit("formSubmit", { isValid, erros: fields.value.filter(el => el.error.length > 0).map(el => el.error) });
                });
        };
        
        provide("fields", fields);
        provide("addField", setField);
        provide("onInput", onInput);
        provide("validateField", validateField);

        return { submit, form };
    }
});

export default Form;
</script>

<style>

</style>