<template>
    <div class="w-full h-screen">

        <FacialBiometry @checkbiometry="check" :loading="loading"
                        title="Cadastro de biometria facial"
                        subtitle="Encaixe seu rosto no formato e clique no botão abaixo"/>
        <Modal v-model:isOpened="modalOpened" width="24rem">

            <div class="h-72 flex overflow-hidden relative">
                <Transition name="slide">
                    <div class="w-full h-full flex flex-col items-center justify-between p-9 flex-shrink-0" v-if="modalPageIndex == 0">
                        <div class="h-32 flex items-center justify-center">
                            <img src="@/assets/img/pictureIllustration1.svg"/>
                        </div>
                        <p class="text-sm">É necessario realizar a captura da sua face antes de prosseguirmos, esteja atento às instruções que aparecerão na tela e execute a ação solicitada.</p>
                    </div>

                    <div class="w-full h-full flex flex-col items-center justify-between p-9 flex-shrink-0" v-else-if="modalPageIndex == 1">
                        <div class="h-32 flex items-center justify-center">
                            <img src="@/assets/img/pictureIllustration2.svg"/>
                        </div>
                        <p class="text-sm">Tente encaixar o seu rosto no formato indicado na tela. Não utilize óculos, boné ou adornos que possam interferir no reconhecimento facial.</p>
                    </div>

                    <div class="w-full h-full flex flex-col items-center justify-between p-9 flex-shrink-0" v-else-if="modalPageIndex == 2">
                        <div class="h-32 flex items-center justify-center">
                            <img src="@/assets/img/pictureIllustration3.svg"/>
                        </div>
                        <p class="text-sm">Após realizar a captura, confira a foto e confirme para finalizar o seu cadastro.</p>
                    </div>
                </Transition>

                <div class="absolute w-full flex justify-center bottom-3">
                    <div class="w-1 h-1 rounded-full bg-gray-200 ml-1" :class="{'bg-primary': modalPageIndex == 0 }"></div>
                    <div class="w-1 h-1 rounded-full bg-gray-200 ml-1" :class="{'bg-primary': modalPageIndex == 1 }"></div>
                    <div class="w-1 h-1 rounded-full bg-gray-200 ml-1" :class="{'bg-primary': modalPageIndex == 2 }"></div>
                </div>
            </div>

            <!-- Footer -->
            <div class="w-full border-t border-neutral-2">
                <button class="outline-none h-16 flex items-center justify-center w-full" @click="modalPageIndex == 2 ? modalOpened = false : modalPageIndex += 1">
                    {{ modalButtonText }}
                </button>
            </div>
        </Modal>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, ref } from 'vue';
import vm from '@/viewModels/RegisterViewModel';
import FacialBiometry from '@/components/FacialBiometry.vue';
import { useAlert } from '@/plugins/alert';
import { useRouter } from 'vue-router';
import Modal from '@/components/Modal.vue';

const TakePictureView = defineComponent({
    components: { FacialBiometry, Modal },
    setup() {
        const alert = useAlert();
        const { replace } = useRouter();

        const loading = ref(false);
        const modalOpened = ref(true);
        const modalPageIndex = ref(0);

        const modalButtonText = computed(() => {
            switch (modalPageIndex.value) {
                case 0:
                    return "Continuar";
                case 1:
                    return "Próximo";
                case 2:
                    return "Iniciar captura";
                default:
                    return "";
            }
        });

        const check = (data: { picture: string; reset: () => void}) => {
            loading.value = true;
            const picture = data.picture.substr(22, data.picture.length);

            vm.submit(picture)
                .then(() => replace({ name: 'download' }))
                .catch(error =>  {
                    loading.value = false;
                    data.reset();
                    alert({
                        message: error
                    });
                });
        };

        return { loading, check, modalOpened, modalPageIndex, modalButtonText };
    }
});

export default TakePictureView;
</script>

<style>
.slide-enter-from, .slide-leave-from {
    transform: translateX(0);
}
.slide-enter-to, .slide-leave-to {
    transform: translateX(-100%)
}
.slide-enter-active, .slide-leave-active {
    transition: transform .3s;
}
</style>