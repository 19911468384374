
import { computed, defineComponent, ref } from 'vue';
import vm from '@/viewModels/RegisterViewModel';
import FacialBiometry from '@/components/FacialBiometry.vue';
import { useAlert } from '@/plugins/alert';
import { useRouter } from 'vue-router';
import Modal from '@/components/Modal.vue';

const TakePictureView = defineComponent({
    components: { FacialBiometry, Modal },
    setup() {
        const alert = useAlert();
        const { replace } = useRouter();

        const loading = ref(false);
        const modalOpened = ref(true);
        const modalPageIndex = ref(0);

        const modalButtonText = computed(() => {
            switch (modalPageIndex.value) {
                case 0:
                    return "Continuar";
                case 1:
                    return "Próximo";
                case 2:
                    return "Iniciar captura";
                default:
                    return "";
            }
        });

        const check = (data: { picture: string; reset: () => void}) => {
            loading.value = true;
            const picture = data.picture.substr(22, data.picture.length);

            vm.submit(picture)
                .then(() => replace({ name: 'download' }))
                .catch(error =>  {
                    loading.value = false;
                    data.reset();
                    alert({
                        message: error
                    });
                });
        };

        return { loading, check, modalOpened, modalPageIndex, modalButtonText };
    }
});

export default TakePictureView;
