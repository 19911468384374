export const validEmail = (value: string): Promise<string> => {
    return new Promise((resolve) => {
        const isValid = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
        resolve(!isValid ? "O campo $NAME precisa ser um e-mail válido" : "");
    });
};

export const required = (value: string): Promise<string> => {
    return new Promise((resolve) => {
        const isValid = (value && value.toString() as string).trim().length > 0;
        resolve(!isValid ? "O campo $NAME é obrigatório" : "");
    });
};

export const validCpf = (value: string): Promise<string> => {
    return new Promise((resolve) => {
        const isValid = (/[0-9]{3}.[0-9]{3}\.?[0-9]{3}-[0-9]{2}/i).test(value);
        resolve(!isValid ? "O campo $NAME precisa ser um CPF válido" : "");

    });
};

export const validPhone = (value: string): Promise<string> => {
    return new Promise((resolve) => {
        const isValid = (/\(\d{2,}\) \d{4,}-\d{4}/).test(value);
        resolve(!isValid ? "O campo $NAME precisa ser um telefone válido" : "");
    });
};

export const isSame = (value: string, valueToCompare: string) => {
    return new Promise((resolve) => {
        const isValid = value === valueToCompare;
        resolve(!isValid ? "Os campos não conferem" : "");
    });
};

export const validDate = (value: string): Promise<string> => {
    return new Promise((resolve) => {
        const isValid = (/(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/).test(value);
        resolve(!isValid ? "O campo $NAME precisa ser uma data válida": "");
    });
};