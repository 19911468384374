
import { defineComponent, ref } from 'vue';
import { required, validEmail } from '@/utils/validationFunctions';
import { useRoute, useRouter } from 'vue-router';
import { useMediaPermissions } from '@/plugins/mediaPermission';

import Form from '@/components/Form.vue';
import LoginInput from '@/components/LoginInput.vue';
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';

import UsuarioService from '@/services/UsuarioService';
import vm from '@/viewModels/MainViewModel';

const LoginFormView = defineComponent({
    components: { Form, LoginInput, ConfigureMediaModal },
    setup() {

        // Hooks
        const route = useRoute();
        const router = useRouter();
        const mediaPermission = useMediaPermissions();

        // State
        const email = ref("");
        const senha = ref("");
        const loading = ref(false);
        const erro = ref("");

        // refs
        const mediaModal = ref<typeof ConfigureMediaModal | null>(null);

        // Submit
        const submit = async (data: { isValid: true; erros: string[] }) => {
            erro.value = '';
            if(data.isValid) {
                try {
                    loading.value = true;
                    const [ request ] = UsuarioService.Login({ emailOuCpf: email.value, chave: senha.value });
                    
                    const resp = await request;

                    const role = resp.role.descricao.toUpperCase();

                    // Caso esteja na pagina de aluno
                    if(route.name == 'login-candidato') {
                        if(role == "ALUNO"){
                            const granted = await mediaPermission();
                            if(!granted) {
                                throw 'A permissão de acesso a câmera é necessária';
                            }
                            else {
                                await mediaModal.value!.configure();
                                vm.user = resp;
                                router.replace({ name: 'agenda' });
                            }
                        }
                        else {
                            erro.value = "Usuário não existe para o papel de aluno.";
                        }
                    }

                    // Caso esteja na pagina de administrador
                    if(route.name == 'login') {
                        if(role == "ADMIN"){
                            vm.user = resp;
                            router.replace({ name: 'admin' });
                        }
                        else {
                            erro.value = "Usuário não existe para o papel de admin.";
                        }
                    }
                    
                } catch (error) {
                    erro.value = error;
                }
                finally {
                    loading.value = false;
                }

                // request
                //     .then(resp => {
                //         const role = resp.role.descricao.toUpperCase();
                //         // Caso esteja na pagina de aluno
                //         if(route.name == 'login-candidato') {
                //             if(role == "ALUNO"){
                //                 mediaPermission()
                //                     .then(granted => {
                //                         return granted ? mediaModal.value!.configure() : Promise.reject('A permissão de acesso a câmera é necessária');
                //                     })
                //                     .then(() => {
                //                         vm.user = resp;
                //                         router.replace({ name: 'agenda' });
                //                     })
                //                     .catch((error: any) => {
                //                         erro.value = error;
                //                     });
                //             }
                //             else {
                //                 erro.value = "Usuário não existe para o papel de aluno.";
                //             }
                //         }

                //         // Caso esteja na pagina de administrador
                //         if(route.name == 'login') {
                //             if(role == "ADMIN"){
                //                 vm.user = resp;
                //                 router.replace({ name: 'admin' });
                //             }
                //             else {
                //                 erro.value = "Usuário não existe para o papel de admin.";
                //             }
                //         }
                //     })
                //     .catch(error => erro.value = error)
                //     .finally(() => loading.value = false);
            }
            else {
                erro.value = data.erros[0];
            }
        };

        return { email, senha, loading, submit, required, validEmail, erro, mediaModal };
    }
});

export default LoginFormView;
