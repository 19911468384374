<template>
    <div class="w-full">

        <!-- Aproveita daqui pra baixo -->
        <p class="font-medium">Olá, candidado</p>
        <p class="mb-12 mb-">Para continuar, precisamos realizar seu cadastro</p>
        <Form @formSubmit="submit">
            <LoginInput placeholder="Digite o seu CPF" name="cpf" v-model="vm.form.cpf" class="mb-5" :rules="[required, validateUserCpf]" mask="###.###.###-##"/>
            <LoginInput placeholder="Digite o seu nome completo" name="nome" v-model="vm.form.nome" class="mb-5" :rules="[required]"/>
            <LoginInput placeholder="Digite o seu email" name="email" v-model="vm.form.email" class="mb-5" :rules="[required, validateUserEmail]"/>
            <!-- <LoginInput placeholder="Digite a sua data de aniversário" name="aniversário" v-model="vm.form.dataNascimento" class="mb-5" :rules="[required, validDate]" mask="##/##/####"/> -->
            <LoginInput placeholder="Digite o seu telefone" name="telefone" v-model="vm.form.telefones[0].numero" class="mb-5" :rules="[required, validPhone]" mask="(##) #####-####"/>
            <LoginInput placeholder="Digite a sua senha" name="senha" v-model="vm.form.senha" class="mb-5" :rules="[required]" type="password"/>
            <LoginInput placeholder="Confirme a sua senha" name="confirmar senha" v-model="confirmarSenha" class="mb-5" :rules="[required, (value) => isSame(value, vm.form.senha)]" type="password"/>

            <button class="bg-primary w-full rounded-lg h-14 font-medium flex items-center justify-center">
                <transition name="fade" mode="out-in">
                    <Loading v-if="loading"/>
                    <div v-else class="text-white font-medium">Próximo</div>
                </transition>
            </button>
        </Form>

        <ConfigureMediaModal ref="mediaModal"/>
    </div>
</template>

<script lang='ts'>
import { defineComponent, ref } from 'vue';
import { required, validEmail, validCpf, validPhone, isSame, validDate } from '@/utils/validationFunctions';

import LoginInput from '@/components/LoginInput.vue';
import Form from "@/components/Form.vue";
import ConfigureMediaModal from '@/components/ConfigureMediaModal.vue';
import Loading from '@/components/Loading.vue';

import UsuarioService from '@/services/UsuarioService';
import { useRouter } from 'vue-router';
import vm from '@/viewModels/RegisterViewModel';
import { useMediaPermissions } from '@/plugins/mediaPermission';

const RegisterPage = defineComponent({
    components: { LoginInput, Form, ConfigureMediaModal, Loading },
    setup() {
        // Hooks
        const { push } = useRouter();
        const checkPermission = useMediaPermissions();
        const confirmarSenha = ref("");
        const mediaModal = ref<typeof ConfigureMediaModal | null>(null);
        const loading = ref(false);


        const validateUserCpf = (cpf: string) => {
            return new Promise((resolve) => {
                validCpf(cpf)
                    .then(resp => {
                        if(resp.length > 0) {
                            resolve(resp);
                        }
                        else {
                            const [request] = UsuarioService.GetUsers({ 
                                cpf: cpf.replace(new RegExp("[.-]", "gi"), '')
                            });
                            request
                                .then(user => {
                                    resolve(user.length > 0 ? "Ja existe um usuário cadastrado com esse CPF" : "");
                                })
                                .catch(() => {
                                    resolve("Não foi possível validar o CPF, por favor, tente novamente");
                                });
                        }
                    });
               
            });
        };

        const validateUserEmail = (email: string) => {
            return new Promise((resolve) => {
                validEmail(email)
                    .then(resp => {
                        if(resp.length > 0) {
                            resolve(resp);
                        }
                        else {
                            const [request] = UsuarioService.GetUsers({ email });
                            request
                                .then(user => {
                                    resolve(user.length > 0 ? "Ja existe um usuário cadastrado com esse e-mail" : "");
                                })
                                .catch(() => {
                                    resolve("Não foi possível validar o CPF, por favor, tente novamente");
                                });
                        }
                    });
               
            });
        };

        const submit = async (data: { isValid: boolean; erros: string[]}) => {
            
            if(data.isValid) {
                loading.value = true;
                const granted = await checkPermission();
                if(granted) {
                    await mediaModal.value!.configure();
                    push({ name: 'picture' });
                }
                loading.value = false;
            }
        };

        return { vm, required, submit, validPhone, isSame, confirmarSenha, validDate, validateUserCpf, validateUserEmail, mediaModal, loading };
    }
});

export default RegisterPage;
</script>

<style>

</style>