
import { computed, defineComponent, inject, onMounted, PropType, Ref } from 'vue';
import { FormField } from './Form.vue';
import Loading from './Loading.vue';
import { maska } from 'maska';
const LoginInput = defineComponent({
    directives: { maska },
    components: { Loading },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        rules: {
            type: Array as PropType<((value: string) => Promise<string>)[]>,
        },
        hasButton: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        mask: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        },
        displayError: {
            type: Boolean,
            default: true
        },
        buttonInError: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const addField = inject<(data: FormField) => null>("addField");
        const fields = inject<Ref<FormField[]>>('fields');
        const onInput = inject<(fieldName: string, value: string) => Promise<void>>('onInput');
        const validateField = inject<(fieldName: string, value: string) => Promise<void>>('validateField');

        const error = computed(() => fields?.value.find(el => el.name == props.name)?.error || "");

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
                onInput && onInput(props.name, value);
            }
        });

        const onBlur = () => {
            validateField!(props.name, inputValue.value);
        };

        onMounted(() => {
            if(fields && props.rules && addField) {
                addField({ name: props.name, error: "", validation: props.rules });
            }
        });

        return { inputValue, error, onBlur };
    }
});

export default LoginInput;
