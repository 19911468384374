<template>
    <div class="w-full relative">
        <input :placeholder="$attrs['placeholder']"
               v-model="inputValue"
               :type="$attrs['type']"
               v-maska="mask"
               @focus="$emit('focus', $event)"
               @blur="$emit('blur', $event); onBlur()"
               :name="name"
               class="w-full outline-none border px-3
                        flex items-center
                    border-gray-300 focus:border-primary
                      rounded-lg h-14 transition-colors duration-300"
               :class="[{'pr-12': hasButton}, { 'border-red-500': error.length > 0 }]"/>
        <p class="text-red-500 text-xs pt-1" v-if="displayError">{{ error }}</p>

        <button :class="{'bg-primary': !buttonInError}"
                class="absolute w-8 h-8 bg-red-500 rounded-md right-0 mr-3 flex items-center justify-center transition-all duration-300" style="top: 0.75rem"
                
                v-if="hasButton">
            <transition name="fade" mode="out-in">
                <Loading v-if="loading"/>
                <img src="@/assets/icons/error.svg" v-else-if="buttonInError"/>
                <img src="@/assets/icons/icon_check.svg" v-else/>

            </transition>
        </button>
    </div>
</template>

<script lang='ts'>
import { computed, defineComponent, inject, onMounted, PropType, Ref } from 'vue';
import { FormField } from './Form.vue';
import Loading from './Loading.vue';
import { maska } from 'maska';
const LoginInput = defineComponent({
    directives: { maska },
    components: { Loading },
    props: {
        modelValue: {
            type: String,
            required: true
        },
        rules: {
            type: Array as PropType<((value: string) => Promise<string>)[]>,
        },
        hasButton: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        mask: {
            type: String
        },
        loading: {
            type: Boolean,
            default: false
        },
        displayError: {
            type: Boolean,
            default: true
        },
        buttonInError: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const addField = inject<(data: FormField) => null>("addField");
        const fields = inject<Ref<FormField[]>>('fields');
        const onInput = inject<(fieldName: string, value: string) => Promise<void>>('onInput');
        const validateField = inject<(fieldName: string, value: string) => Promise<void>>('validateField');

        const error = computed(() => fields?.value.find(el => el.name == props.name)?.error || "");

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: any) {
                context.emit("update:modelValue", value);
                onInput && onInput(props.name, value);
            }
        });

        const onBlur = () => {
            validateField!(props.name, inputValue.value);
        };

        onMounted(() => {
            if(fields && props.rules && addField) {
                addField({ name: props.name, error: "", validation: props.rules });
            }
        });

        return { inputValue, error, onBlur };
    }
});

export default LoginInput;
</script>

<style>

</style>